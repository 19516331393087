import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'

import { Button, EMPTY_GROUPS_PAYLOAD } from 'components/common'
import { DataSourcePanel } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/DataSourcePanel/DataSourcePanel'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { clone } from 'services/Utils/misc'
import { validateCriteriaPayload } from 'services/Utils/validator'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'

import type { MetadataDescription } from 'models/metadata.model'
import type { BreadcrumbInfo, ConfigPanelPayload } from 'models/motion/motionBuilder.model'

interface RecordLocatorModalProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const RecordLocatorModal = observer(({ payload, setPayload }: RecordLocatorModalProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)
  const { configPanelStore, metadataStore } = useStore()
  const { get, setCurrentItem, setBreadCrumbItems, setFreezeBreadcrumbs } = metadataStore
  const { displayRecordLocatorModal, setDisplayRecordLocatorModal } = configPanelStore
  const [modalPayload, setModalPayload] = useState(EMPTY_GROUPS_PAYLOAD as ConfigPanelPayload)
  const [modalPayloadError, setModalPayloadError] = useState(false)

  useEffect(() => {
    if (displayRecordLocatorModal) {
      const options = {
        platform: segmentBuilderData.platform,
        object: segmentBuilderData.object,
        solutionInstanceId: segmentBuilderData.solutionInstanceId,
        ...(segmentBuilderData.magnifyDisplayName && { magnifyDisplayName: segmentBuilderData.magnifyDisplayName }),
      }
      const defaultBreadcrumbs: BreadcrumbInfo[] = [
        {
          name: segmentBuilderData.platform,
          path: [segmentBuilderData.platform],
          entityType: 'platform',
        },
        {
          name: segmentBuilderData.object,
          path: [segmentBuilderData.platform, segmentBuilderData.object],
          entityType: 'object',
          ...(segmentBuilderData.magnifyDisplayName && { magnifyDisplayName: segmentBuilderData.magnifyDisplayName }),
        },
      ]

      get(options).catch(console.error)
      setBreadCrumbItems(defaultBreadcrumbs)
      setCurrentItem(options as MetadataDescription, true)
      setModalPayload(clone(payload || EMPTY_GROUPS_PAYLOAD))
      setFreezeBreadcrumbs(true)
    } else {
      setFreezeBreadcrumbs(false)
    }
  }, [displayRecordLocatorModal])

  useMetadataDisplayErrorNotification(metadataStore)

  const handleSubmit = () => {
    const validCriteria: boolean = validateCriteriaPayload({ payload: modalPayload, requiredPayload: false })
    if (validCriteria) {
      setPayload((prev) => {
        return { ...prev, ...modalPayload }
      })
      setDisplayRecordLocatorModal(false)
    } else {
      setModalPayloadError(true)
      setTimeout(() => {
        setModalPayloadError(false)
      }, 5000)
    }
  }

  const handleCancel = () => {
    setModalPayload(clone(payload || EMPTY_GROUPS_PAYLOAD))
    setDisplayRecordLocatorModal(false)
  }

  return (
    <>
      <Modal
        key={`data-source-modal-${segmentBuilderData.id || segmentBuilderData.nodeId}`}
        open={displayRecordLocatorModal}
        title='Record locator criteria'
        centered
        className='modal__data-source'
        onCancel={handleCancel}
        footer={[
          <React.Fragment
            key={`modal__form--footer modal-footer-${segmentBuilderData.id || segmentBuilderData.nodeId}`}>
            {modalPayloadError && (
              <label className='error-message' data-testid='error-message'>
                You cannot apply criteria without a value.
              </label>
            )}
            <Button type='secondary' text='Cancel' data-testid='cancel-btn' onClickHandler={handleCancel} />
            <Button type='primary' text='Apply' data-testid='apply-btn' onClickHandler={handleSubmit} />
          </React.Fragment>,
        ]}>
        <div className='segment-builder' data-testid='segment-builder'>
          <DataSourcePanel displayHeader={false} payload={modalPayload} setPayload={setModalPayload} />
        </div>
      </Modal>
    </>
  )
})

export default RecordLocatorModal
