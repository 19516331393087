import { datadogRum } from '@datadog/browser-rum'
import { signOut } from 'aws-amplify/auth'
import { jwtDecode } from 'jwt-decode'
import { clearStorage } from 'utils/clearStorage'

import type { RoleEnum } from 'services/Utils/Role'
import type { UserStore } from 'store/user.store'
import useStore from 'store/useStore'

/**
 * Check for a token in localStorage and attempt to parse it as a JWT and set that values to the User Store.
 */
export const checkForAuthTokenSetUser = (userStore: UserStore) => {
  const hasToken = typeof localStorage.getItem('ADMIN_TOKEN') === 'string'
  // We have a token, but we need to manually add it to the store on refresh.
  if (hasToken) {
    try {
      // Decode masquerade token to needed values
      const decoded = jwtDecode<{
        username: string
        email: string
        ['cognito:username']: string
        ['custom:roleId']: string
        ['custom:tenantId']: string
        ['custom:tenantName']: string
      }>(localStorage.getItem('ADMIN_TOKEN') as string)

      // Update the user store
      userStore.setUser({
        username: decoded.username,
        email: decoded.email,
        roleId: decoded['custom:roleId'] as RoleEnum,
        name: decoded['cognito:username'],
        tenantId: decoded['custom:tenantId'],
        tenantName: decoded['custom:tenantName'],
      })
      return
    } catch (error: unknown) {
      console.error(error)
    }
  }
}

export const SignOut = () => {
  const { userStore } = useStore()

  signOut().catch(console.error)

  // Clear out anything from the browser storage
  clearStorage('SignOut')

  // Clear out the data dog session
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#clear-user-session-property
  try {
    datadogRum.clearUser()
  } catch (error: unknown) {
    console.log('Error clearing user in Datadog', error)
  }

  // Check for admin auth URL structure
  try {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const masquerade = params.get('masquerade')
    const host = params.get('host')

    if (masquerade && host) {
      // Setup the host for the Admin Bar
      localStorage.setItem(
        'API_OPTIONS',
        JSON.stringify({
          VPN: {
            CORE_API: `https://${host}`,
          },
        }),
      )
      localStorage.setItem('ADMIN_PANEL', 'true')
      localStorage.setItem('ADMIN_TOKEN', masquerade)

      // Setup user based on their token
      checkForAuthTokenSetUser(userStore)

      // Go to the dashboard
      window.location.href = `/dashboard`
    } else {
      window.location.href = `/`
    }
  } catch (error: unknown) {
    console.error('Error:', error)
  }

  return <span></span>
}
SignOut.displayName = 'SignOut'
