import message from 'antd/lib/message'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'utils/isEqual'

import type { Elements, FlowElement } from 'react-flow-renderer'

import type { AggregationData, Groups, Item, PayloadData } from 'models/motion/motionBuilder.model'
import type {
  ActionFieldsPayload,
  Choice,
  CurrentMotion,
  Dsl,
  Motion,
  MotionIdentifiers,
  NodePayload,
  NodePosition,
  NodeState,
  PayloadGroup,
  States,
} from 'models/motion.model'
import { MotionStateEnum, MotionStatusEnum } from 'models/motion.model'

export type Action = `${MotionStatusEnum}`

export const successMotionMessage = (action: Action) => {
  return `Successfully ${action} Motion`
}

type RecursiveDslKeys = {
  [key: string]:
    | string
    | States
    | AggregationData[]
    | AggregationData
    | PayloadData
    | Groups
    | Item
    | NodeState
    | NodePayload
    | NodePosition
    | boolean
    | Choice[]
    | Choice
    | PayloadGroup
    | ActionFieldsPayload
    | Dsl
}
/**
 * Recursive utility function that performs deep omitting of specific keys from an object or an array of objects.
 * @param dsl The input object or array of objects from which keys will be omitted recursively.
 * @param ignoredKeys An array of keys that need to be omitted from the dsl object(s)
 * @returns The object without the omitted keys
 */
export const deepOmit = (
  dsl: RecursiveDslKeys,
  ignoredKeys: Array<keyof NodeState>,
): RecursiveDslKeys | RecursiveDslKeys[] => {
  if (Array.isArray(dsl)) {
    return dsl.map((item: RecursiveDslKeys) => deepOmit(item, ignoredKeys)) as RecursiveDslKeys[]
  } else if (dsl && typeof dsl === 'object') {
    return Object.keys(dsl).reduce((acc, key) => {
      if (!ignoredKeys.includes(key as keyof NodeState)) {
        acc[key] = deepOmit(dsl[key] as RecursiveDslKeys, ignoredKeys)
      }
      return acc
    }, {} as RecursiveDslKeys)
  }
  return dsl
}

const IGNORED_KEYS: Array<keyof NodeState> = ['validationErrors', 'isInvalid', 'iconName']

/**
 * A function that compares 2 DSLs ignoring specified keys
 * @param apiDsl The DSL object from the API
 * @param localDsl The DSL object created based on UI node elements
 * @returns True if there are differences between objects
 */
export const isDSLDifferent = (apiDsl: Dsl | undefined, localDsl: Dsl) => {
  if (!apiDsl) {
    return false
  }

  // delete DSL title when is compared to avoid false positives for old Motions that still have the title
  delete apiDsl.title
  const normlaizedApiDsl = deepOmit({ ...cloneDeep(apiDsl) }, IGNORED_KEYS)
  const normlaizedLocalDsl = deepOmit({ ...cloneDeep(localDsl) }, IGNORED_KEYS)

  return !isEqual(normlaizedApiDsl, normlaizedLocalDsl)
}

export const isSegmentDSLDifferent = (apiDsl: Dsl | undefined, localDsl: Dsl) => {
  if (!apiDsl) {
    return false
  }

  const apiSegmentDSL = apiDsl?.startAt ? apiDsl?.states[apiDsl?.startAt] : {}
  const localSegmentDSL = localDsl?.startAt ? localDsl?.states[localDsl?.startAt] : {}

  return !isEqual(apiSegmentDSL, localSegmentDSL)
}

export const isOperatorAndActionDSLDifferent = (apiDsl: Dsl | undefined, localDsl: Dsl) => {
  if (!apiDsl) {
    return false
  }

  const remoteDSL = { ...cloneDeep(apiDsl) }
  const clientDSL = { ...cloneDeep(localDsl) }

  if (remoteDSL.startAt) {
    delete remoteDSL.states[remoteDSL.startAt]
  }
  if (clientDSL.startAt) {
    delete clientDSL.states[clientDSL.startAt]
  }

  return !isEqual(remoteDSL, clientDSL)
}

export const getMotionCalloutMessage = (currentMotion: CurrentMotion) => {
  return currentMotion?.currState === MotionStateEnum.Executing ? 'Running' : currentMotion?.currState
}

/**
 * Title & description can be changed in any status
 *
 * Secondary checks then depend on what status the Motion is currently in
 * Button is only enabled if:
 *   - In draft and anything in the DSL itself has changed
 *   - In executing or scheduled, is a multi-run Motion and only segment DSL changed
 *
 * @returns {boolean} True if save button should be enabled, otherwise false.
 */
export const determineWhetherSaveIsEnabled = (
  currentMotion: CurrentMotion,
  hasTitleOrDescriptionChanged: boolean,
  hasOverallDSLChanged: boolean,
  hasSegmentDSLChanged: boolean,
  hasOperationAndActionDSLChanged: boolean,
  isMultiRunMotion: boolean,
  hasGoalsChanged: boolean,
  hasMetricsChanged: boolean,
  enableDemoMockApi: boolean,
) => {
  if (enableDemoMockApi) {
    return true
  }

  if (hasTitleOrDescriptionChanged && !hasOverallDSLChanged) {
    return true
  }

  switch (currentMotion?.currState) {
    case MotionStateEnum.Draft:
      if (hasOverallDSLChanged || hasGoalsChanged || hasMetricsChanged) {
        return true
      } else {
        return false
      }
    case MotionStateEnum.Executing:
    case MotionStateEnum.Scheduled:
      if (hasSegmentDSLChanged && !hasOperationAndActionDSLChanged) {
        return true
      }

      return false
    default:
      return false
  }
}

const getMotionExecuteData = (motion: Motion): MotionIdentifiers => ({
  playbookId: motion.playbookId,
  version: motion.version,
})

export const cloneMotion = async (
  motion: Motion,
  clone: (motion: MotionIdentifiers) => Promise<void>,
  getAll: (forceRefresh?: boolean, limit?: number, offset?: number) => Promise<void>,
) => {
  const cloneData = getMotionExecuteData(motion)

  try {
    await clone(cloneData)
    void message.success(successMotionMessage(MotionStatusEnum.Cloned))

    // Refresh the Motions to prevent the user from having to manually refresh the entire application
    await getAll(true)
  } catch (error: unknown) {
    void message.error('Failed to clone Motion')
  }
}

export const cancelMotion = async (
  motion: MotionIdentifiers,
  cancel: (motion: MotionIdentifiers) => Promise<void>,
  getAll: (forceRefresh?: boolean, limit?: number, offset?: number) => Promise<void>,
) => {
  try {
    await cancel(motion)
    void message.success(successMotionMessage(MotionStatusEnum.Canceled))

    // Refresh the Motions to prevent the user from having to manually refresh the entire application
    await getAll(true)
  } catch (error: unknown) {
    void message.error('Failed to cancel Motion')
  }
}

export const archiveMotion = async (
  motion: Motion | undefined,
  archive: (motion: MotionIdentifiers) => Promise<void>,
  getAll: (forceRefresh?: boolean, limit?: number, offset?: number) => Promise<void>,
) => {
  if (!motion) {
    void message.error('Failed to archive Motion: No Motion found.')
    return
  }

  const archiveData = getMotionExecuteData(motion)

  try {
    // Refresh the Motions to prevent the user from having to manually refresh the entire application
    await archive(archiveData)
    void message.success(successMotionMessage(MotionStatusEnum.Archived))

    await getAll(true)
  } catch (error: unknown) {
    void message.error('Failed to archive Motion')
  }
}

export const isMotionExecutionBtnEnabled = (elements: Elements) => {
  return elements.length >= 3 && elements.some((element: FlowElement<{ action?: any }>) => element.data?.action)
}
